import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';

const COMPANIES_TO_MAP_FOR_SELECT = [
  'companies',
  'company_groups',
  'primary_companies',
  'secondary_companies',
  'peer_companies',
  'portfolio_companies',
];

export class ContentOrdersStore {
  isLoading = false;

  isLoadingAddUsers = false;

  contentOrder = {};

  companies = [];

  isLoadingCompanies = false;

  companyTypes = [];

  isLoadingCompanyTypes = false;

  articleSources = [];

  isLoadingArticleSources = false;

  validationErrors = {};

  isUpdating = false;

  nlaOrganisations = [];

  isLoadingNlaOrganisations = false;

  validationErrorsAddUsers = {};

  contentOrderUsers = {};

  usersFile = null;

  briefingTypes = [];

  isLoadingBriefingTypes = false;

  constructor() {
    makeAutoObservable(this);
  }

  setContentOrderField = (value, field) => {
    this.contentOrder[field] = value;
  };

  setContentOrderUsers = (values) => {
    this.contentOrderUsers = {...this.contentOrderUsers, ...values};
  };

  setUsersFile = (file) => {
    this.usersFile = file;
  };


  fetchContentOrder = async (id, mapForSelect = false) => {
    this.isLoading = true;

    try {
      const response = await API.get(apiRoutes.contentOrder(id));
      runInAction(() => {
        const { content_order: contentOrder } = response.data;

        if (mapForSelect) {
          COMPANIES_TO_MAP_FOR_SELECT.forEach((key) => {
            contentOrder[key] = contentOrder[key].map(
              ({ id: value, name: label }) => ({
                value,
                label,
              }),
            );
          });
          contentOrder.article_sources = contentOrder.article_sources.map(
            ({ id: value, title: label }) => ({
              value,
              label: `${label} (${value})`,
            }),
          );

          contentOrder.excluded_article_sources = contentOrder.excluded_article_sources.map(
            ({ id: value, title: label }) => ({
              value,
              label: `${label} (${value})`,
            }),
          );

          contentOrder.nla_organisations = contentOrder.nla_organisations.map(
            ({ id: value, name: label }) => ({
              value,
              label: `${label} (${value})`,
            }),
          );

          contentOrder.briefing_types = contentOrder.briefing_types.map(
            ({ id: value, name: label }) => ({
              value,
              label: `${label} (${value})`,
            }),
          );
        }
        this.contentOrder = contentOrder;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchCompanies = async () => {
    this.isLoadingCompanies = true;
    this.companies = [];

    const query = {
      limit: 10000,
      skip: 0,
      company: '',
    };

    try {
      const response = await API.get(apiRoutes.companies.all, {
        params: query,
      });
      runInAction(() => {
        this.companies = response.data.companies.map(
          ({ id: value, name: label }) => ({ value, label }),
        );
        this.isLoadingCompanies = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchArticleSources = async () => {
    this.isLoadingArticleSources = true;
    try {
      const { data } = await API.get(
        `${apiRoutes.articleSources.index}?limit=10000`,
      );
      this.articleSources = data.article_sources.map(
        ({ id: value, title: label }) => ({
          value,
          label: `${label} (${value})`,
        }),
      );
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoadingArticleSources = false;
    }
  };

  fetchCompanyTypes = async () => {
    this.isLoadingCompanyTypes = true;
    this.companyTypes = [];

    try {
      const response = await API.get(apiRoutes.companies.companyTypes);
      this.companyTypes = response.data.company_types.map(
        ({ id: value, name: label }) => ({ value, label }),
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingCompanyTypes = false;
    }
  };

  fetchBriefingTypes = async () => {
    this.isLoadingBriefingTypes = true;
    this.briefingTypes = [];

    try {
      const response = await API.get(apiRoutes.briefingTypes());
      this.briefingTypes = response.data.briefingTypes.map(
        ({ id: value, name: label }) => ({ value, label }),
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingBriefingTypes = false;
    }
  };

  cloneArticleSource = async (copyFromId, copyToId) => {
    try {
      const response = await API.post(
        apiRoutes.cloneContentOrderArticleSources,
        { copyFromId, copyToId },
      );
      if (response.errors) {
        toast.error('Something went wrong!');
      } else {
        toast.success('Article sources successfully copied!');
        this.fetchContentOrder(copyToId);
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    }
  };

  bulkAddSources = async (contentOrderId, sourcesToAdd) => {
    try {
      const response = await API.post(
          apiRoutes.bulkAddSourcesToContentOrder(contentOrderId),
          { article_source_ids: sourcesToAdd },
      );
      if (response.errors) {
        toast.error('Something went wrong!');
      } else {
        toast.success('Article sources successfully added!');
        this.fetchContentOrder(contentOrderId);
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    }
  };

  toggleSection = (section, type, allAction) => {
    if (section.content_container_id !== '') {
      section[type] = !section[type];
    }

    if (type === 'row') {
      section.bespoke_sections = allAction === 'selectAll';
      section.standard_sections = allAction === 'selectAll';
    }

    this.contentOrder.content_order_sections = [
      ...this.contentOrder.content_order_sections,
    ];
  };

  toggleColumn = (column, action) => {
    this.contentOrder.content_order_sections.forEach((section) => {
      section[column] = action === 'selectAll';
    });
    this.contentOrder.content_order_sections = [
      ...this.contentOrder.content_order_sections,
    ];
  };

  updateContentOrder = async (shouldRefresh) => {
    if (!this.validateContentOrder()) {
      toast.error('Form has errors!');
      return;
    }
    this.isUpdating = true;
    const contentOrder = { ...this.contentOrder };
    COMPANIES_TO_MAP_FOR_SELECT.forEach((key) => {
      contentOrder[key] = contentOrder[key].map(({ value }) => value);
    });
    contentOrder.article_sources = contentOrder.article_sources.map(
      ({ value }) => value,
    );

    contentOrder.excluded_article_sources = contentOrder.excluded_article_sources.map(
      ({ value }) => value,
    );

    contentOrder.nla_organisations = contentOrder.nla_organisations.map(
      ({ value }) => value,
    );

    contentOrder.briefing_types = contentOrder.briefing_types.map(
      ({ value }) => value,
    );

    try {
      await API.put(apiRoutes.contentOrder(contentOrder.id), { contentOrder });
      if (shouldRefresh) {
        await this.fetchContentOrder(contentOrder.id, true);
      } else {
        routerStore.push(`${routes.contentOrder}?id=${contentOrder.id}`);
      }
      toast.success('Content order updated!');
    } catch (e) {
      console.log(e);
      toast.error(e.errors);
    } finally {
      this.isUpdating = false;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  validateContentOrder = () => {
    this.validationErrors.name =
      this.contentOrder.name === null || this.contentOrder.name === ''
        ? 'Name should not be empty'
        : undefined;

    this.validationErrors.user_limit =
      this.contentOrder.user_limit &&
      this.contentOrder.users.length &&
      this.contentOrder.user_limit <= this.contentOrder.users.length
        ? 'If you want to reduce user limit, you have to remove users from this contract.'
        : undefined;

    this.validationErrors.user_limit =
      !this.validationErrors.user_limit &&
      (this.contentOrder.user_limit === null ||
        this.contentOrder.user_limit === '')
        ? 'User limit should not be empty'
        : this.validationErrors.user_limit;

    return Object.values(this.validationErrors).filter((e) => !!e).length === 0;
  };

  fetchNlaOrganisations = async () => {
    this.isLoadingNlaOrganisations = true;
    try {
      const { data } = await API.get(
        `${apiRoutes.nlaOrganisations.index}?limit=10000`,
      );
      this.nlaOrganisations = data.nla_organisations.map(
        ({ id: value, name: label }) => ({
          value,
          label: `${label} (${value})`,
        }),
      );
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoadingNlaOrganisations = false;
    }
  };

  clearContentOrder = () => {
    this.contentOrder = {};
  };

  validateContentOrderAddUsers = () => {
    this.validationErrorsAddUsers.content_order_id =
      this.contentOrderUsers.content_order_id === null || this.contentOrderUsers.content_order_id === 0
        ? 'Content order id should not be empty'
        : undefined;

    this.validationErrorsAddUsers.content_container_id =
      this.contentOrderUsers.content_container_id === null || this.contentOrderUsers.content_container_id === 0
        ? 'Content container id should not be empty'
        : undefined;

    this.validationErrorsAddUsers.invitations_list =
      this.usersFile &&
      this.usersFile.type.includes('sheet')
        ? undefined
        : 'Invitations list file in XLSX format is required'

    return Object.values(this.validationErrorsAddUsers).filter((e) => !!e).length === 0;
  };

  createUsers = async () => {
    this.isLoadingAddUsers = true;

    if (!this.validateContentOrderAddUsers()) {
      toast.error('Form has errors!');
      this.isLoadingAddUsers = false;
      return;
    }

    const formData = new FormData();
    formData.append('invitations_list', this.usersFile, this.usersFile.name);

    Object.entries(this.contentOrderUsers).forEach(([key, value]) => {
      if(key !== 'id') {
        formData.append(key, value);
      }
    });

    try {
      await API.post(apiRoutes.contentOrdersAddUsers(this.contentOrderUsers.id), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Users will be added shortly!');
    } catch (e) {
      toast.error(e.message);
    } finally {
      this.isLoadingAddUsers = false;
    }
  };
}

export default new ContentOrdersStore();
